:root {
    --background-color: #fafafb;
    --secondary-color: #161616;
    --text-color-main: #161616;
    --text-color-secondary: #927979;

    --user-message: #38434f;
    --user-message-text: #ffffff;
    --bot-message: #4962ec;
    --bot-message-text: #ffffff;
    
    --inline-button-color: #dbd4c8;
    --inline-button-text: #161616;

    --border-color: #dadada;
    --input-border-color:#4962ec;
    --input-background-color:#ffffff;
    --text-color-light:#edf2fc;

    --navbar-color: #f6f6f6;
    --icon-active-filter: brightness(0) saturate(100%) invert(100%) sepia(16%) saturate(7398%) hue-rotate(345deg) brightness(114%) contrast(75%);
    --icon-inactive-filter: brightness(0) saturate(100%) invert(85%) sepia(33%) saturate(439%) hue-rotate(107deg) brightness(103%) contrast(105%);
}

body {
    background-color: #fafafb; 
}

.role-heading {
    font-size: 12px;
    margin-bottom:2px;
    margin-top:5px;
    font-weight: 800;
}

/* Layout */

.chat-box-container {
    max-width: 700px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 70px;
    /* padding-bottom: 115px; */
    height: calc(100vh - 90px);
}
.window {
    overflow-y: auto;
    height: calc(100vh - 90px);
    margin-left: 30px;
    margin-right: 30px;
}

/* Input */

.custom-query-area {
    padding: 24px;
    color: var(--text-color-main);
    background-color: var(--background-color);
    border-top: 1px solid #ccc;
    position: fixed; 
    bottom: 0;
    z-index: 5;
    width: 100%;
    max-width: 700px;
    box-sizing: border-box;
    padding-bottom: 40px;
}

/* Chat UI */

.messages-container {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-bottom: 2px;
    font-size: 16px;
}

.user-message-container,
.assistant-message-container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin: 4px 0;
}

.user-message-container {
    align-self: flex-end;
    align-items: flex-end;
}

.assistant-message-container {
    align-items: flex-start;
}

/* Message Boxes  */

.message {
    white-space: pre-line;
    padding: 14px;
    margin: 2px 0;
    border-radius: 10px;
    clear: both;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.4;
    text-align: left;
}

.user-message {
    align-self: flex-end;
    font-size: 13px;
    background-color:var(--user-message);
    color: var(--user-message-text);
    border-top-right-radius: 0;
    text-align: left;
}

.assistant-message {
    align-self: flex-start;
    background-color:var(--bot-message);
    border-top-left-radius: 0 !important;
    color: #161616;
    padding: 14px;
    font-size: 13px;
    width: 100%;
    box-sizing: border-box;
}

::-webkit-scrollbar {
    display: none;
}

.user-message a {
color: #789888;
}

.ai-message a {
    color: #ffd200;
}

/* Placeholder Message */

.bar-container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    gap: 5px; 
    align-self: flex-start;
    background-color:var(--bot-message);
    border-radius: 10px;
    border-top-left-radius: 0;
    padding: 14px;
    font-size: 13px; 
    margin: 2px 0;
}

.bar {
    height: 20px; 
    border-radius: 10px; 
    background: linear-gradient(270deg, #ebebeb, #f1f1f1, #d1d1d1); 
    background-size: 200% 200%; 
    animation: moving-gradient 3s linear infinite;
}

.bar-short {
    width: 50%; 
}

.bar-medium {
    width: 75%; 
}

.bar-long {
    width: 100%; 
}

@keyframes moving-gradient {
0% {
    background-position: 0% 50%;
}
50% {
    background-position: 100% 50%;
}
100% {
    background-position: 0% 50%;
}
}

/* Heading  */
  
.top-container {
    position: fixed; 
    width:100%;
    max-width: 700px;
    top: 0px;
    z-index: 10;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px; 
    background-color: var(--background-color);
    border-bottom: 1px solid #ccc;
}
.heading-buttons {
    display: flex;
    align-items: center;
}
.heading-button-group {
    display: flex;
    justify-content: center;
    padding-left: 50px;
    z-index: 200;
}
.powered-by {
    width: 200px;
    display: flex;
    margin-left:20px
}

/* Spinner */

.progress-input-area {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: var(--background-color);
    color:var(--text-color-main);
}

.progress-circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    position: relative;
    cursor: pointer;
}
  
.progress-circle {
    border: 4px solid #dddbd9; 
    border-top: 4px solid #666666;
    border-radius: 50%;
    width: 40px; 
    height: 40px; 
    animation: spin 1.4s ease-in-out infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
  
.stop-square {
    width: 13px;
    height: 13px;
    background-color: #666666; 
    position: absolute; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
}
